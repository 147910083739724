<template>
  <v-container class="pa-4 text-center">
    <PageTitle text="Contact Sarah" />
    <v-row class="text-left">
      <v-col cols="1" md="2"></v-col>
      <v-col cols="10" md="8">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Info</v-list-item-title>
            <v-list-item-subtitle>
              Fax: 208-209-6047
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="1" md="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="1" md="2"></v-col>
      <v-col cols="10" md="8">
        <v-carousel>
          <v-carousel-item
            v-for="(img,i) in office_images"
            :key="i"
            :src="img"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="1" md="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  components: { PageTitle },
  data: () => ({
    email: 'sarahbjorn@coeurcounseling.net',
    office_images: [
      'img/office_seating.jpeg',
      'img/office_desk.jpeg',
      'img/office_chair.jpeg',
      'img/office_bookshelf.jpeg',
    ],
  }),
};
</script>

<style scoped>
.v-list-item__title {
   font-size: 30px;
}
.v-list-item__subtitle {
   font-size: 18px;
}
</style>
